import React from 'react'

function Footer() {
  return (
    <footer class="site-footer">
      <p class="text-center pt-2"><a href="#">vimoservices.com&nbsp;</a>copyright&copy;&nbsp;2024</p>
  </footer>
  )
}

export default Footer