import React from 'react'
import BookingManagement from './BookingManagement'

const CanceledJob = () => {
  return (
    <BookingManagement 
        type = "Canceled Jobs"
    />
  )
}

export default CanceledJob