import React from 'react'
import BookingManagement from './BookingManagement'

const ScheduledJob = () => {
  return (
    <BookingManagement 
        type = "Scheduled Jobs"
    />
  )
}

export default ScheduledJob