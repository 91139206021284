import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Footer from './Footer';
import { ApiCall, ApiKey } from '../common/Apikey'
import { toast } from 'react-toastify';



const BookingManagement = (props) => {
    const [data,setData] = useState("");
    const [formData, setFormData] = useState({
        booking_id: '',
        Pname: ''
    });
    const [ShowAction, setShowAction] = useState('');
    const[filter,setFilter] = useState(false);
    const [bookingid,setBookingId] = useState('');
    const [rowDatas, setRowDatas] = useState('');
    const showFilter = () =>{
        setFilter(!filter);
    }
    const BookingsList = async () =>{
        const userData = localStorage.getItem('userData');
        let staff_id;
        let type;
        if (userData) {
            const data = JSON.parse(userData);
            staff_id = data.id;
        }
        if(window.location.pathname == '/currentJobs'){
            type = "current";
        }else if(window.location.pathname == '/scheduledJobs'){
            type = 'schedule';
        }else if(window.location.pathname == '/completedJobs'){
            type = 'complete';
        }else{
            type = 'cancell';
        }
        setShowAction(type);
        let data = {
            staff_id: staff_id,
            type: type,
        };
        await ApiCall('booking/bookingsList', data).then((data) => {
            if (data.message === "success") {
                setData(data.result);
            }
        }).catch(err => console.log(err));
    }
    useEffect(()=>{
        BookingsList();
    },[]);

    const inputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        const filteredResult = FilteringFunction(formData);
        setData(filteredResult);
    };
    const FilteringFunction = (formData) => {
        return data.filter(item => {
            const bookingIdMatch = formData.booking_id === '' || (item.booking_id && item.booking_id.toString().includes(formData.booking_id));
            const pnameMatch = formData.Pname === '' || (item.Passenger_name && item.Passenger_name.toLowerCase().includes(formData.Pname.toLowerCase()));
            return bookingIdMatch && pnameMatch;
        });
    };
   
    const handleEditClick = (id) => {
       setBookingId(id);
    };

    const handleCancel = (rowData) =>{
        setRowDatas(rowData);
        // console.log(rowData);
    }

    const confirmCancel =async () =>{
        let data = {
            booking_id: rowDatas.booking_id,
        }
        await ApiCall('booking/staff_cancell', data).then((data) => {
            if (data.message === "Request saved successfully.") {
                toast.success('Trip cancelled successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                  setTimeout(() => {
                    window.location.href = '/scheduledJobs'
                  }, 3000);
            }
        }).catch(err => console.log(err));
    }
    const actionTemplate = (rowData) => {
        return (
            <>
                {rowData.Status == "Driver Assigned" ? '---' : 
                <div className="d-flex justify-content-center">
                    <a href={`/Booking_details?id=${bookingid}`} onClick={() => handleEditClick(rowData.booking_id)}><i class="fa-solid fa-pen-to-square"></i></a>
                    <a href='#' data-bs-toggle="modal" data-bs-target="#cancelTrip" onClick={() => handleCancel(rowData)}><i class="fa-solid fa-ban ms-1"></i></a>
                </div>
                }
            </>
        );
    };

return (
    <>
    <Navbar />
     <section id="booking-management" class="pt-4 vimo-body">
        <div class="employee">
            <div class="row">
                <div class="col">
                    <h3><b>{props.type}</b></h3>
                </div>
                <div class="col text-end pt-2">
                    <button type="button" class="btn btn-sm bm-button" id="filter-button" onClick={showFilter}>Filter | <i class="fa fa-filter"
                            style={{color:"white"}} ></i></button>
                </div>
            </div>
            <div className="container pt-3" 
            style={{ display: filter==true ? "block" : "none"}} 
            id="bm-filter">
            <div className="row justify-content-center">
                <div className="col-md-8 col-11 filter-box">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <label for="booking_id" class="form-label">Booking Id</label>
                            <input type="number" className="form-control" placeholder="Booking Id" name='booking_id' id="booking_id"onChange={inputChange} min="0"/>
                        </div>
                        <div className="form-group mb-3">
                            <label for="Pname" class="form-label">Passenger Name</label>
                            <input type="text" class="form-control" id="Pname" name="Pname"
                                 placeholder="Passenger Name" aria-label="Passenger Name" onChange={inputChange}/>
                        </div>
                        <div class="d-flex gap-2 mb-2">
                            <button type="submit" className="btn btn-success btn-sm" ><i class="fa fa-search"
                                    aria-hidden="true"></i>&nbsp;Submit</button>
                            <button type="reset" className="btn btn-warning btn-sm" id='booking_reset' onClick={BookingsList}><i
                                    class="fa fa-refresh" aria-hidden="true"></i>&nbsp;Reset</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal fade" id="cancelTrip" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Are you sure you want cancel this trip?</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                        <button type="button" class="btn btn-danger" onClick={confirmCancel}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
            <div className="card mt-5">
            <DataTable value={data} showGridlines paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem', }} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" >
                <Column field="booking_id" header="Booking ID" sortable style={{ width: '' }}></Column>
                <Column field="date_time" header="Date / Time" sortable style={{ width: '' }}></Column>
                <Column field="driver full name" header="Driver Full Name" sortable style={{ width: '' }}></Column>
                {/* <Column field="employee name" header="Employee Name" sortable style={{ width: '' }}></Column> */}
                <Column field="Pickup Point" header="Pickup Point" sortable style={{ width: '' }}></Column>
                <Column field="Drop Point" header="Drop Point" sortable style={{ width: '' }}></Column>
                <Column field="Type Of Services" header="Type Of Services" sortable style={{ width: '' }}></Column>
                <Column field="Type Of Vehicle" header="Type Of Vehicle" sortable style={{ width: '' }}></Column>
                <Column field="Passenger_name" header="Passenger Name" sortable style={{ width: '' }}></Column>
                <Column field="Passenger Contact Number" header="Passenger Contact Number" sortable style={{ width: '' }}></Column>
                {/* <Column field="Drop Point" header="Drop Point" sortable style={{ width: '' }}></Column> */}
                <Column field="Total Amount" header="Total Amount" sortable style={{ width: '' }}></Column>
                {/* <Column field="Last Edited By" header="Last Edited By" sortable style={{ width: '' }}></Column> */}
                {ShowAction == 'schedule' && (<Column field="Status" header="Status" sortable style={{ width: '' }}></Column>)}
                {ShowAction == 'schedule' && (<Column header="Action" body={actionTemplate}  sortable style={{ width: '' }}></Column>)}
                {/* <Column field="Action" header="Action" sortable style={{ width: '' }}></Column> */}
            </DataTable>
        </div>
        </div>
    </section>
    <Footer />
</> 
)
}

export default BookingManagement
