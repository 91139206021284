import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer,toast } from 'react-toastify';
import logo from '../assets/image/logo_big_new.jpeg';
import { useSelector } from 'react-redux';
export const Navbar = () => {
    const [trip_data, setTripData] = useState('');
    const cartData = useSelector((state) => state.cart.AddCart);
    useEffect(() => {
        setTripData(cartData.length > 0 ? cartData : '')
    }, [cartData])


    function employeeLogout(){
        localStorage.clear();  // to clear the local storage
        toast.success("Logout successfully",{
            position: toast.POSITION.TOP_RIGHT,
            className: "custom_toast",
            loader: true, // Change it to false to disable loader
            loaderBg: "#9EC600"
        });
        setTimeout(function(){
            window.location.href = "/login";
        }, 2000);   
        localStorage.clear('userData');  // to clear the local storage
    }   

  return (
    <>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
    />
    <nav className="navbar navbar-expand-lg bg-body-tertiary pb-0" >
        <div className="container-fluid" style={{backgroundColor:"white"}}>
            <a className="navbar-brand head_nav" href="#">
                <img src={logo}  alt="" />
            </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 heading_li">
                <li className="nav-item dropdown">
                    <a className="nav-link header_text" href="#" data-bs-toggle="dropdown" aria-expanded="false">Booking
                        Management&nbsp; <i className="fa fa-caret-down" style={{color: "#c26c7c"}} aria-hidden="true"></i>
                    </a>
                    <ul className="dropdown-menu fade-up">
                        <li>
                            <a className="nav-link dropdown-item nav-sub-link" href="/currentJobs" id="current_jobs">Current Jobs</a>
                        </li>
                        <li>
                            <a className="nav-link dropdown-item nav-sub-link" href="/scheduledJobs" id="scheduled_jobs">Scheduled Jobs</a>
                        </li>
                        <li>
                            <a className="nav-link dropdown-item nav-sub-link" href="/completedJobs" id="completed_jobs">Completed Jobs</a>
                        </li>
                        <li>
                            <a className="nav-link dropdown-item nav-sub-link" href="/canceledJobs" id="canceled_jobs">Canceled Jobs</a>
                        </li>
                    </ul>
                </li>
                <li className="nav-item" style={{display : window.location.pathname == '/booking' ? "none" : "block"}}>
                    <a className="nav-link header_text" href="booking">Create Booking</a>
                </li>
                <li className="nav-item" style={{display : window.location.pathname == '/booking' ? "block" : "none"}}>
                    <a className="nav-link header_text" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-backdrop="false" href="#">View Cart({trip_data.length})</a>
                </li>
                <li className="nav-item dropleft">
                    <a className="nav-link header_text logoutBtn" href="login" data-bs-toggle="modal" data-bs-target="#employeelogout" >Log Out&nbsp;&nbsp;<i class="fa fa-sign-out" aria-hidden="true" style={{color:"#c26c7c"}}></i></a>
                    <ul className="dropdown-menu fade-up">
                        <li className="nav-item" >
                            {/* <div class="d-flex"><img src="{{ env('APP_URL') . '/images/login-logo-new.png' }}" alt=""
                                    width="60" height="60">
                                <span style="margin-left: 6px;font-size:15px"><span style="font-weight: bold;font-size:18px">Employee</span><br>{{ }} {{}}<br>{{  }}</span>
                            </div> */}
                        </li>
                    </ul>
                </li>
                <div class="modal fade" id="employeelogout" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">Are you sure you want to log Out?</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                                <button type="button" class="btn btn-danger" onClick={employeeLogout}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
        </div>
    </nav>
    </>
  )
}
export default Navbar;
