import "./App.css";
import "./Custom.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./component/Login";
import Home from "./component/Home";
import { Provider } from "react-redux";
import store, { persist } from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import VerifyOtp from "./component/EditBooking/VerifyOtp";
import NotFound from "./component/EditBooking/NotFound";
import NoEdit from "./component/EditBooking/NoEdit";
import EditBooking from "./component/EditBooking/EditBooking";
import CurrentJob from "./component/CurrentJob";
import ScheduledJob from "./component/ScheduledJob";
import CompletedJob from "./component/CompletedJob";
import CanceledJob from "./component/CanceledJob";
import Welcome from "./component/Welcome";
import { useEffect } from "react";

function App() {
  useEffect(() =>{
    const userData = localStorage.getItem('userData');
    if(userData){
      const data = JSON.parse(userData);
      if(data.id != null){
        if(window.location.pathname == '/login'){
            window.location.href = '/booking';
        }
      }
    }else{
      if(window.location.pathname != '/login'){
        window.location.href = '/login';
    }    }
  },[]);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persist}>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" element={<Welcome/>} /> */}
            <Route path="/login" element={<Login/>} />
            <Route path="/booking" element={<Home />} />
            <Route path="/BookverifyOtp/:id" element={<VerifyOtp />} />
            <Route path="/notFound" element={<NotFound />} />
            <Route path="/noEdit" element={<NoEdit />} />
            <Route path="/Booking_details" element={<EditBooking />} />
            <Route path="/currentJobs" element={<CurrentJob />} />
            <Route path="/scheduledJobs" element={<ScheduledJob />} />
            <Route path="/completedJobs" element={<CompletedJob />} />
            <Route path="/canceledJobs" element={<CanceledJob />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
