import React, { useState } from 'react'
import logo from '../assets/image/login-logo-new.png';
import { ApiCall, ApiKey } from '../common/Apikey';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formError, setFormError] = useState({emailErr: "", passwordErr: ""})

    const FormValidation = () => {
        let isValid = true;
        setFormError({})
        if (email.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'emailErr': 'Enter Email' }))
        }
        if (password.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'passwordErr': 'Enter Password' }))
        }
        if (email !== "") {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2)) {
                isValid = false;
                setFormError(prevError => ({ ...prevError, 'emailErr': 'Enter Valid Email' }))
            }
        }
        return isValid;
    }
    const SuccessRedirect = () => {
        toast.success('Login successfully', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          window.location.href = window.location.origin + '/booking';
        }, 3000);
      }
    const handleSubmit = async (e) =>{
        e.preventDefault();
        if(FormValidation()){
            // console.log('Hello');
            let data = {
                email:email,
                password:password,
                type:"staff"
            }
            await ApiCall('auth/login', data).then((data) => {
                if(data.message === "Login Successfull"){
                    const userData = {
                    'id' : data.result.id,
                    'name' : data.result.user_name,
                    }
                    localStorage.setItem('userData',JSON.stringify(userData));
                    SuccessRedirect()
                }else{
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch(err => console.log(err));
        };
    };

  return (
    <>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    <section className="login-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 pt-5 mb-2">
                        <div className="sec-title">
                            <img className="" src={logo} alt="" />
                            <h1 className="">Vimo Services</h1>
                        </div>
                        <form id="employee_login" action="" onSubmit={handleSubmit} method="post">
                            <div className="login-form">
                                <h2>Staff Login</h2>
                                <div className="enter-phone form-outline px-3">
                                    <label className="form-label" htmlFor="typeNumber">Enter your Email</label>
                                    <input type="email" name="email" id="employee_email" placeholder="Enter Email" autoComplete="off" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.emailErr}</span>
                                </div>

                                <div className="enter-phone form-outline px-3">
                                    <label className="form-label" htmlFor="typeNumber">Enter Password</label>
                                    <input type="password" name="password" id="employee_password" placeholder="Enter Password" autoComplete="off" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}/>
                                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.passwordErr}</span>
                                </div>
                                <input type="hidden" name="emp_resend_status" id="emp_resend_status" value="0" />
                            
                                <div className="login-sub px-3">
                                    <button type="submit" id="employee_login_button" className="login-btn">Login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Login