import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import Select from 'react-select';
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import store from '../redux/Store';
import { ApiCall, ApiKey } from '../common/Apikey';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ThreeDots } from 'react-loader-spinner';
import VerifyModel from './VerifyModel';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from "react-tooltip";
import AddonModel from './AddonModel';

function AirportBook() {

    const AutocompleteRestrict = {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: ["sg"] },  //, "my"
    };

    const cartData = useSelector((state) => state.cart.AddCart);

    let timeConstraints = {
        minutes: {
            step: 5
        }
    }

    // addon
    const [AddonShow, setAddonShow] = useState(0);
    const [AddonStatus, setAddonStatus] = useState(0);
    const [ReAddonStatus, setReAddonStatus] = useState(0);
    const [OverallVehicle, setOverallVehicle] = useState();
    const [VehicleAddonData, setVehicleAddonData] = useState();
    const [hasRendered, setHasRendered] = useState(false);
    const [AddonData, setAddonData] = useState([]);
    const [ReAddonData, setReAddonData] = useState([]);
    const [AddonTripType, setAddonTripType] = useState('from');

    const AddonModelOpen = () => {
        setVehicleAddonData([])
        setHasRendered(false);
        setAddonTripType('from')
        if (AddonStatus == 0) {
            OverallVehicle.filter(function (option) {
                if (option.id == vehicle_type) {
                    setVehicleAddonData(option)
                }
            })
            setHasRendered(true);
            setAddonStatus(1)
            setTimeout(() => {
                $('#AddonDataModel').click();
            }, 1000);
        } else {
            setAddonStatus(0)
            setAddonData([])
        }
    }

    const AddonModelEdit = () => {
        setVehicleAddonData([])
        setHasRendered(false);
        setAddonTripType('from')
        OverallVehicle.filter(function (option) {
            if (option.id == vehicle_type) {
                setVehicleAddonData(option)
            }
        })
        setHasRendered(true);
        setTimeout(() => {
            $('#AddonDataModel').click();
        }, 1000);
    }

    const AddonModelOpenRe = () => {
        // console.log('sdsdsd')
        setVehicleAddonData([])
        setHasRendered(false);
        setAddonTripType('return')
        if (ReAddonStatus == 0) {
            OverallVehicle.filter(function (option) {
                if (option.id == vehicle_type) {
                    setVehicleAddonData(option)
                }
            })
            setHasRendered(true);
            setReAddonStatus(1)
            setTimeout(() => {
                $('#AddonDataModel').click();
            }, 1000);
        } else {
            setReAddonStatus(0)
            setReAddonData([])
        }
    }

    const AddonModelEditRe = () => {
        setVehicleAddonData([])
        setHasRendered(false);
        setAddonTripType('return')
        OverallVehicle.filter(function (option) {
            if (option.id == vehicle_type) {
                setVehicleAddonData(option)
            }
        })
        setHasRendered(true);
        setTimeout(() => {
            $('#AddonDataModel').click();
        }, 1000);
    }

    const getAddon = async (data) => {
        const addonData = await data;
        if(addonData != 'close'){
            if (AddonTripType == 'from') {
                addonData.length > 0 ? setAddonStatus(1) : setAddonStatus(0);
                setAddonData([])
                setAddonData(addonData)
            } else {
                addonData.length > 0 ? setReAddonStatus(1) : setReAddonStatus(0);
                setReAddonData([])
                setReAddonData(addonData)
            }
        }
        setHasRendered(false);
    }

    const [StopDivs, setStopDivs] = useState([]);
    const [flightDiv, setflightDiv] = useState(0)
    const [changeflightDiv, setchangeflightDiv] = useState(0)
    const [vehDiv, setvehDiv] = useState(0)

    const [AirDate, setAirDate] = useState("")
    const [arrival_time, setArrivalTime] = useState("")
    const [flight_no, setFlightNo] = useState("")
    const [from_lat, setFromLat] = useState("1.3547390928883953")
    const [from_lon, setFromLon] = useState("103.98854785212832")
    const [pickup_location, setPickupLocation] = useState("Airport Boulevard, Singapore Changi Airport, Singapore")
    const [to_places, setToPlaces] = useState("")
    const [to_lat, setToLat] = useState("")
    const [to_lon, setToLon] = useState("")
    const [drop_location, setDropLocation] = useState("")
    const [Passenger, setPassenger] = useState("1")
    const [Wheelchair, setWheelchair] = useState("0")
    const [Luggage, setLuggages] = useState("0")
    const [vehicle_type, setVehicleType] = useState("")
    const [vehicle_name, setVehicleName] = useState("")
    const [short_name, setShortName] = useState("")
    const [email, setEmail] = useState("")
    const [main_name, setMainName] = useState("")
    const [cc, setCc] = useState("+65")
    const [phone, setPhone] = useState("")
    const [comment, setComment] = useState("")
    const [flight_actual_arrival_time, setflight_actual_arrival_time] = useState("")
    const [flight_carrier_code, setflight_carrier_code] = useState("")
    const [flight_code, setflight_code] = useState("")
    const [ride_date, setride_date] = useState("")
    const [flight_date, setflight_date] = useState("")
    const [pick_up_time, setPickUpTime] = useState("")
    const [airport_check, setairport_check] = useState(0)



    const [email_verify, setEmailVerify] = useState(0)
    const [phone_verify, setPhoneVerify] = useState(0)
    const [country_code, setCountryCode] = useState([]);
    const [flight_data, setFlightData] = useState([])
    const [cart_button, setCartButton] = useState(0);
    const [Vehicleoptions, setVehicleoptions] = useState([
        { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
    ])

    const [formError, setFormError] = useState({ date: "", arrival: "", flight: "", drop: "", vehicle: "", short: "", main: "", cc: "", phone: "", emailErr: "", rpickup: "", rdrop: "", rdate: "", rtime: "", stop1: "", stop2: "", rstop1: "", rstop2: "" })

    const [mailverifybtn, setmailverifybtn] = useState(0)
    const [mobileverifybtn, setmobileverifybtn] = useState(0)

    const [fareData, setFareData] = useState([])

    // airport return
    const [ReturnDiv, setReturnDiv] = useState(0)
    const [rfrom_places, setRFromPlaces] = useState("")
    const [rfrom_lat, setRFromLat] = useState("")
    const [rfrom_lon, setRFromLon] = useState("")
    const [rpickup_location, setRPickupLocation] = useState("")
    const [rto_places, setRToPlaces] = useState("")
    const [rto_lat, setRToLat] = useState("")
    const [rto_lon, setRToLon] = useState("")
    const [rdrop_location, setRDropLocation] = useState("")
    const [rcomment, setRComment] = useState("")
    const [ReSchDate, setReSchDate] = useState("")
    const [ReSchTime, setReSchTime] = useState("")
    const [ReStopDivs, setReStopDivs] = useState([]);
    const [pick_name, setFromPlaceName] = useState("")
    const [drop_name, setDropPlaceName] = useState("")
    const [driver_payout_amount, setDriverPayoutAmount] = useState("")
    const [rdriver_payout_amount, setRDriverPayoutAmount] = useState("")
    // drop
    const toLoad = (autocomplete) => {
        setToPlaces(autocomplete)
    }

    const onToPlaceChanged = async () => {
        if (to_places !== null) {
            var dropLat = to_places.getPlace().geometry.location.lat()
            var dropLon = to_places.getPlace().geometry.location.lng()
            var dropAdd = to_places.getPlace().formatted_address
            var dropName = to_places.getPlace().name

            setToLat(dropLat)
            setToLon(dropLon)
            setDropLocation(dropAdd)
            // local storage
            localStorage.setItem('pick_up', pickup_location);
            const PickPosition = {
                lat: 1.3547390928883953,
                lng: 103.98854785212832
            }
            localStorage.setItem('pickupPosition', JSON.stringify(PickPosition))
            localStorage.setItem('drop', dropName);
            const DropPosition = {
                lat: dropLat,
                lng: dropLon
            }
            localStorage.setItem('dropoffPosition', JSON.stringify(DropPosition))
            window.dispatchEvent(new Event("storage"));

            // return
            setRToLat('1.3547390928883953')
            setRToLon('103.98854785212832')
            setRDropLocation('Airport Boulevard, Singapore Changi Airport, Singapore')
            setFromPlaceName('Airport Boulevard, Singapore Changi Airport, Singapore')

            setDropPlaceName(dropName);
            setRFromLat(dropLat)
            setRFromLon(dropLon)
            setRPickupLocation(dropAdd)
        }
    }

    const onDateChange = (event) => {
        setAirDate(event)
    }

    // passenger
    const passengerIn = (event) => {
        let value = Passenger;
        value++;
        setPassenger(value)
    }

    const passengerOut = (event) => {
        let value = Passenger;
        if (value > 1) {
            value--
        }
        setPassenger(value)
    }

    // wheel chair
    const wheelchairIn = (event) => {
        let value = Wheelchair;
        value++;
        setWheelchair(value)
    }

    const wheelchairOut = (event) => {
        let value = Wheelchair;
        if (value > 0) {
            value--
        }
        setWheelchair(value)
    }
    // luggage
    const luggageIn = (event) => {
        let value = Luggage;
        value++;
        setLuggages(value)
    }

    const luggageOut = (event) => {
        let value = Luggage;
        if (value > 0) {
            value--
        }
        setLuggages(value)
    }

    // Single Add Stop
    const RemoveStop = (i) => {
        const deletVal = [...StopDivs]
        deletVal.splice(i, 1)
        setStopDivs(deletVal)
        // local storage
        localStorage.removeItem('stopPosition' + i)
        window.dispatchEvent(new Event("storage"));
    }

    const inputRefs = useRef([]);
    const fromhandleChange = (ref, index) => {
        inputRefs.current[index] = ref;
    }

    const handleChange = async (place, i) => {
        const inputdata = [...StopDivs]
        if (place) {
            var stopLat = place.geometry.location.lat()
            var stopLon = place.geometry.location.lng()
            inputdata[i] = {
                lat: stopLat,
                long: stopLon,
                stop_name: place.formatted_address,
            };
        }
        setStopDivs(inputdata)
        // local storage
        const StopPosition = {
            lat: stopLat,
            lng: stopLon
        }
        localStorage.setItem('stopPosition' + i, JSON.stringify(StopPosition))
        window.dispatchEvent(new Event("storage"));
    }

    const AddStop = () => {
        const data = [...StopDivs, []]
        setStopDivs(data)
    }

    const checkFlight = async () => {
        if (AirDate == "") {
            toast.error('Select date', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return false
        }

        if (flight_no.trim() == "") {
            toast.error('Enter flight number', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return false
        }

        let data = {
            date: moment(AirDate).format('YYYY-MM-DD'),
            flight_number: flight_no,
        }
        await ApiCall('getScheduledFlightDetails', data).then((data) => {
            if (data.message === "Flight details listed successfully") {
                setFlightData(data.result[0])

                var dataFgt = data.result[0]
                setflight_actual_arrival_time(dataFgt.FlightArrivalTime)
                setflight_carrier_code(dataFgt.carrierFsCode)
                setflight_code(dataFgt.flightNumber)
                setride_date(moment(dataFgt.FlightArrivalTime).format('YYYY-MM-DD'))
                setflight_date(moment(dataFgt.FlightArrivalTime).format('YYYY-MM-DD'))
                setairport_check(1)
                setflightDiv(1)
                setchangeflightDiv(1)
                // pick_up_time calculation
                var flightArrivalTime = new Date(dataFgt.FlightArrivalTime)
                var arrivalTime = parseInt(arrival_time, 10)
                var calTime = arrivalTime * 60000
                var pick_upTime = new Date(flightArrivalTime.getTime() + calTime)
                setPickUpTime(pick_upTime.toTimeString().slice(0, 8))
                ChooseVeh()
            } else {
                toast.error(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return false
            }
        }).catch(err => console.log(err));
    }

    const ChooseVeh = async () => {
        let data = {
            service_type: "airport",
            no_of_seats: Passenger,
            luggage: Luggage,
            wheel_chair: Wheelchair,
        }
        await ApiCall('getVehicleCategoryGuest', data).then((data) => {
            if (data.success === "success") {
                const vechData = data.data;
                setVehicleoptions([
                    { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
                ])
                setOverallVehicle("");
                if (vechData.length > 0) {
                    const newVechOptions = vechData.map((data, i) => ({
                        value: data.id,
                        name: 'vehicle_type',
                        text: data.vehicle_type,
                        label: (
                            <div>
                                <img src={ApiKey.main_name + '/' + data.image} className="option-vehimage" />
                                {data.vehicle_type.split('_').join(" ")}
                            </div>
                        ),
                    }));
                    setVehicleoptions((prevSubOptions) => [...prevSubOptions, ...newVechOptions])
                    setvehDiv(1)
                    setOverallVehicle(vechData)
                }
            } else {
                toast.error('Vehicle category not found.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return false
            }
        }).catch(err => console.log(err));
    }

    const Selectstyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '25px',
        }),
    };

    const selectChange = (event) => {
        if (event.name == 'vehicle_type') {
            setVehicleType(event.value)
            setVehicleName(event.text)
            // addon old hide
            setAddonData([]);
            setHasRendered(false);
            setAddonStatus(0);
            setAddonShow(0);
            // addon check
            OverallVehicle.filter(function (option) {
                if (option.id == event.value && option.addon_services.length > 0) {
                    setAddonShow(1);
                }
            })
        }
    }

    const inputChange = (event) => {
        let { name, value } = event.target;
        if (name == 'arrival_time') {
            setArrivalTime(value)
        } else if (name == 'flight_number') {
            setFlightNo(value)
        } else if (name == 'short_name') {
            setShortName(value)
        } else if (name == 'main_name') {
            setMainName(value)
        } else if (name == 'cc') {
            setCc(value)
        }else if (name == 'amount') {
            if (value.includes('.')) {
                const parts = value.split('.');
                if (parts[1].length > 2) {
                    value = parts[0] + '.' + parts[1].slice(0, 2);
                }
              }
            setDriverPayoutAmount(value);
        } else if (name == 'ramount') {
            if (value.includes('.')) {
                const parts = value.split('.');
                if (parts[1].length > 2) {
                    value = parts[0] + '.' + parts[1].slice(0, 2);
                }
              }
            setRDriverPayoutAmount(value)
        }else if (name == 'phone') {
            setPhone(value)
        } else if (name == 'email') {
            setEmail(value)
        } else {
            setComment(value)
        }
    }

    const FormValidation = () => {
        let isValid = true;
        setFormError({})
        if (AirDate == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'date': 'Select Date' }))
        }
        if (arrival_time == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'arrival': 'Select Arrival Time' }))
        }
        if (flight_no.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'flight': 'Enter Flight Number' }))
        }
        if (drop_location.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'drop': 'Enter Drop Location' }))
        }
        if (vehicle_type == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'vehicle': 'Select Vehicle Type' }))
        }
        if (driver_payout_amount == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'amount': 'Enter Driver Payout Amount' }))
        }
        // if (short_name.trim() == "") {
        //     isValid = false;
        //     setFormError(prevError => ({ ...prevError, 'short': 'Enter Short Name' }))
        // }
        // if (main_name.trim() == "") {
        //     isValid = false;
        //     setFormError(prevError => ({ ...prevError, 'main': 'Enter Passenger Name' }))
        // }
        // if (cc.trim() == "") {
        //     isValid = false;
        //     setFormError(prevError => ({ ...prevError, 'cc': 'Select CC' }))
        // }
        // if (phone.trim() == "") {
        //     isValid = false;
        //     setFormError(prevError => ({ ...prevError, 'phone': 'Enter Phone number' }))
        // }
        // return validation
        if (ReturnDiv == 1 && rpickup_location.trim() == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'rpickup': 'Enter Pickup Location' }))
        }
        if (ReturnDiv == 1 && rdrop_location == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'rdrop': 'Enter Drop Location' }))
        }
        if (ReturnDiv == 1 && ReSchDate == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'rdate': 'Select Date' }))
        }
        if (ReturnDiv == 1 && ReSchTime == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'rtime': 'Select Time' }))
        }
        if (ReturnDiv == 1 && rdriver_payout_amount == "") {
            isValid = false;
            setFormError(prevError => ({ ...prevError, 'ramount': 'Enter Driver Payout Amount' }))
        }

        // stop
        if (StopDivs.length != 0) {
            StopDivs.map((data, i) => {
                if (i == 0 && data.length == 0) {
                    isValid = false;
                    setFormError(prevError => ({ ...prevError, 'stop1': 'Enter Stop 1' }))
                }
                if (i == 1 && data.length == 0) {
                    isValid = false;
                    setFormError(prevError => ({ ...prevError, 'stop2': 'Enter Stop 2' }))
                }
            })
        }
        // return stop
        if (ReStopDivs.length != 0) {
            ReStopDivs.map((data, i) => {
                if (i == 0 && data.length == 0) {
                    isValid = false;
                    setFormError(prevError => ({ ...prevError, 'rstop1': 'Enter Stop 1' }))
                }
                if (i == 1 && data.length == 0) {
                    isValid = false;
                    setFormError(prevError => ({ ...prevError, 'rstop2': 'Enter Stop 2' }))
                }
            })
        }

        return isValid;
    }

    function rideTime(date, time) {
        var changeDate = moment(date).format('DD/MM/YYYY') + ' ' + time;
        var flightArrivalTime = new Date(changeDate)
        var calTime = 5 * 60000;
        var pick_upTime = new Date(flightArrivalTime.getTime() - calTime)

        return moment(pick_upTime).format('YYYY-MM-DD HH:mm')
    }

    // booking
    async function AirportNow(event) {
        event.preventDefault();
        setCartButton(1)
        if (FormValidation()) {

            if (changeflightDiv != 1) {
                toast.error('Please Check Flight Details', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setCartButton(0)
                return false
            }

            if (ReturnDiv == 1) {
                var from_date = moment(AirDate).format('YYYY-MM-DD') + ' ' + pick_up_time
                var to_date = moment(ReSchDate).format('YYYY-MM-DD') + ' ' + moment(ReSchTime, 'hh:mm A').format('HH:mm:ss')
                if (new Date(from_date).getTime() >= new Date(to_date).getTime()) {
                    toast.error('Return time must be greater than 15 mins.', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false
                }
            }

            const Values = await fareAPICall()
            if (Values.message != 'success') {
                toast.error(Values.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setCartButton(0)
                return false
            } else if (Values.result.total_distance == 0) {
                toast.error('Something went wrong. Please try again in a min. Error Code: D0001', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setCartButton(0)
                return false
            }

            // return check
            if (ReturnDiv == 1) {
                const RValues = await SchedulefrData()
                if (RValues.message != "success") {
                    toast.error(Values.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false
                } else if (RValues.result.return_trip.total_distance == 0) {
                    toast.error('Something went wrong. Please try again in a min. Error Code: D0001', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false
                }
            }

            // if (Values.message == "success") {
            //     const inputdata = []
            //     var pick_up = {
            //         lat: from_lat,
            //         long: from_lon,
            //         stop_name: pickup_location,
            //     };
            //     inputdata.push(pick_up)
            //     StopDivs.map((data, i) => {
            //         inputdata.push(data)
            //     })
            //     var drop = {
            //         lat: to_lat,
            //         lon: to_lon,
            //         stop_name: drop_location
            //     }
            //     inputdata.push(drop)
            //     var extra_stop = JSON.stringify(inputdata)
            //     // let ride_time = rideTime(moment(AirDate).format('YYYY-MM-DD'), pick_up_time)
            //     var surge = JSON.stringify(Values.result.surgeprice)
            //     store.dispatch({
            //         type: "addToCart",
            //         payload: {
            //             pick_up: pickup_location,
            //             pickup_lat: from_lat,
            //             pickup_lon: from_lon,
            //             date: moment(AirDate).format('YYYY-MM-DD'),
            //             today_date: moment(AirDate).format('YYYY-MM-DD'),
            //             // ride_time: ride_time,
            //             flight_eta: arrival_time,
            //             flight_number: flight_no.toUpperCase(),
            //             customer_id: "",
            //             mode: 'ridelater',
            //             rider_type: 3,
            //             user_type: 3,
            //             trip_type: 3,
            //             request_type: "web",
            //             service_type: "airport",
            //             ride_date: ride_date,
            //             flight_date: flight_date,
            //             flight_actual_arrival_time: flight_actual_arrival_time,
            //             flight_carrier_code: flight_carrier_code,
            //             flight_code: flight_code,
            //             no_of_adults: Passenger,
            //             luggage: Luggage,
            //             wheel_chair: Wheelchair,
            //             // pick_up_time: pick_up_time,
            //             airport_type: "",
            //             no_of_stops: inputdata.length > 2 ? inputdata.length - 2 : 0,
            //             extra_stops: encodeURIComponent(extra_stop),
            //             return_no_of_stops: 0,
            //             return_extra_stops: "",
            //             return_ride_time: "",
            //             return_type: "",
            //             return_vehicle_type: "",
            //             return_vehicle_id: "",
            //             time_validate: "",
            //             drop_location: drop_location,
            //             drop_lat: to_lat,
            //             drop_lon: to_lon,
            //             vehicle_id: vehicle_type,
            //             short_name: "",
            //             passenger_name: main_name,
            //             passenger_phone_code: cc,
            //             passenger_phone_number: cc + '' + phone,
            //             passenger_email: email,
            //             promocode: "",
            //             special_note: comment,
            //             return_pickup_lat: "",
            //             return_pickup_lon: "",
            //             return_drop_lat: "",
            //             return_drop_lon: "",
            //             type: "Airport",
            //             vehicle_typeName: vehicle_name,
            //             total_amount: Math.ceil(Values.result.amount),
            //             total_distance: Values.result.total_distance,
            //             total_minute: Values.result.total_minute,
            //             extra_stops: Values.result.extra_stops,
            //             extra_stop_status: Values.result.extra_stop_status,
            //             per_stop_fare: Values.result.per_stop_price,
            //             per_stop_increase_cap_fare: Values.result.per_stop_increase_price,
            //             per_stop_multiple: Values.result.per_stop_multiple,
            //             per_stop_increase_multiple: Values.result.per_stop_increase_multiple,
            //             overall_extra_stop_cost: Values.result.overall_extra_stop_cost,
            //             minimum_distance_fare: Values.result.minimum_fare,
            //             minimum_distance_fare_status: Values.result.minimum_fare_status,
            //             sub_total: Values.result.sub_total,
            //             cap_price: Values.result.cap_price,
            //             cap_status: Values.result.capped_status,
            //             base_fare: Values.result.base_fare,
            //             surge_price: Values.result.surgeprice.length > 0 ? surge : '[]',
            //             vehicle_type: vehicle_name,
            //             addon_amount: Values.result.addon_amount,
            //             addon_data: Values.result.addon_data.length > 0 ? Values.result.addon_data : ''
            //         }
            //     });

            //     if (ReturnDiv != 1) {
            //         toast.success('Booking added into cart', {
            //             position: "top-right",
            //             autoClose: 3000,
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //             theme: "colored",
            //         });

            //         setTimeout(() => {
            //             window.location.reload();
            //         }, 5000);
            //     }
            // } 
            if (Values.message == "success") {
                const inputdata = []
                var pick_up = {
                    lat: from_lat,
                    long: from_lon,
                    stop_name: pickup_location,
                };
                inputdata.push(pick_up)
                StopDivs.map((data, i) => {
                    inputdata.push(data)
                })
                var drop = {
                    lat: to_lat,
                    lon: to_lon,
                    stop_name: drop_location
                }
                inputdata.push(drop)
                var extra_stop = JSON.stringify(inputdata)
                // let ride_time = rideTime(moment(AirDate).format('YYYY-MM-DD'), pick_up_time)
                var surge = JSON.stringify(0)
                store.dispatch({
                    type: "addToCart",
                    payload: {
                        pick_up: pickup_location,
                        pickup_lat: from_lat,
                        pickup_lon: from_lon,
                        date: moment(AirDate).format('YYYY-MM-DD'),
                        today_date: moment(AirDate).format('YYYY-MM-DD'),
                        // ride_time: ride_time,
                        flight_eta: arrival_time,
                        flight_number: flight_no.toUpperCase(),
                        customer_id: "",
                        mode: 'ridelater',
                        rider_type: 3,
                        user_type: 3,
                        trip_type: 3,
                        request_type: "web",
                        service_type: "airport",
                        ride_date: ride_date,
                        flight_date: flight_date,
                        flight_actual_arrival_time: flight_actual_arrival_time,
                        flight_carrier_code: flight_carrier_code,
                        flight_code: flight_code,
                        no_of_adults: Passenger,
                        luggage: Luggage,
                        wheel_chair: Wheelchair,
                        // pick_up_time: pick_up_time,
                        airport_type: "",
                        no_of_stops: inputdata.length > 2 ? inputdata.length - 2 : 0,
                        // extra_stops: encodeURIComponent(extra_stop),
                        extra_stops: extra_stop,
                        return_no_of_stops: 0,
                        return_extra_stops: "",
                        return_ride_time: "",
                        return_type: "",
                        return_vehicle_type: "",
                        return_vehicle_id: "",
                        time_validate: "",
                        drop_location: drop_location,
                        drop_lat: to_lat,
                        drop_lon: to_lon,
                        vehicle_id: vehicle_type,
                        short_name: "",
                        passenger_name: main_name,
                        passenger_phone_code: cc,
                        passenger_phone_number: cc + '' + phone,
                        passenger_email: email,
                        promocode: "",
                        special_note: comment,
                        return_pickup_lat: "",
                        return_pickup_lon: "",
                        return_drop_lat: "",
                        return_drop_lon: "",
                        type: "Airport",
                        vehicle_typeName: vehicle_name,
                        total_amount: Math.ceil(driver_payout_amount),
                        total_distance: 0,
                        total_minute: 0,
                        // extra_stops: 0,
                        extra_stop_status: inputdata.length > 2 ? 1 : 0,
                        per_stop_fare: 0,
                        per_stop_increase_cap_fare: 0,
                        per_stop_multiple: 0,
                        per_stop_increase_multiple: 0,
                        overall_extra_stop_cost: 0,
                        minimum_distance_fare: 0,
                        minimum_distance_fare_status: 0,
                        sub_total: Math.ceil(driver_payout_amount),
                        cap_price: Math.ceil(driver_payout_amount),
                        cap_status: 0,
                        base_fare: 0,
                        surge_price: 0 > 0 ? surge : '[]',
                        vehicle_type: vehicle_name,
                        addon_amount: 0,
                        addon_data: 0 > 0 ? 0 : ''
                    }
                });

                if (ReturnDiv != 1) {
                    toast.success('Booking added into cart', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                }
            } else {
                toast.error(Values.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setCartButton(0)
                return false
            }

            // return
            // if (ReturnDiv == 1) {
            //     const inputRdata = []
            //     var pick_up = {
            //         lat: rfrom_lat,
            //         long: rfrom_lon,
            //         stop_name: rpickup_location,
            //     };
            //     inputRdata.push(pick_up)
            //     ReStopDivs.map((data, i) => {
            //         inputRdata.push(data)
            //     })
            //     var drop = {
            //         lat: rto_lat,
            //         lon: rto_lon,
            //         stop_name: rdrop_location
            //     }
            //     inputRdata.push(drop)
            //     var extra_stop = JSON.stringify(inputRdata)
            //     const RValues = await SchedulefrData()
            //     var surge = JSON.stringify(RValues.result.return_trip.surgeprice)
            //     if (RValues.message == "success") {
            //         let ride_time = rideTime(moment(ReSchDate).format('YYYY-MM-DD'), ReSchTime)
            //         store.dispatch({
            //             type: "addToCart",
            //             payload: {
            //                 pick_up: rpickup_location,
            //                 pickup_lat: rfrom_lat,
            //                 pickup_lon: rfrom_lon,
            //                 date: moment(ReSchDate).format('YYYY-MM-DD'),
            //                 pick_up_time: ReSchTime,
            //                 // ride_time: ride_time,
            //                 flight_eta: "",
            //                 flight_number: "",
            //                 customer_id: "",
            //                 mode: "ridelater",
            //                 rider_type: 3,
            //                 user_type: 3,
            //                 trip_type: 3,
            //                 request_type: "web",
            //                 service_type: "normal",
            //                 ride_date: moment(ReSchDate).format('YYYY-MM-DD'),
            //                 flight_date: "",
            //                 flight_actual_arrival_time: "",
            //                 flight_carrier_code: "",
            //                 flight_code: "",
            //                 no_of_adults: "",
            //                 luggage: "",
            //                 wheel_chair: "",
            //                 pick_up_time: ReSchTime,
            //                 airport_type: "",
            //                 no_of_stops: inputRdata.length > 2 ? inputRdata.length - 2 : 0,
            //                 extra_stops: encodeURIComponent(extra_stop),
            //                 return_no_of_stops: inputRdata.length > 2 ? inputRdata.length - 2 : 0,
            //                 return_extra_stops: encodeURIComponent(extra_stop),
            //                 return_ride_time: "",
            //                 return_type: "return",
            //                 return_vehicle_type: vehicle_name,
            //                 return_vehicle_id: vehicle_type,
            //                 time_validate: "1",
            //                 drop_location: rdrop_location,
            //                 drop_lat: to_lat,
            //                 drop_lon: to_lon,
            //                 vehicle_id: vehicle_type,
            //                 short_name: "",
            //                 passenger_name: main_name,
            //                 passenger_phone_code: cc,
            //                 passenger_phone_number: cc + '' + phone,
            //                 promocode: "",
            //                 special_note: rcomment,
            //                 return_pick_up: rpickup_location,
            //                 return_pickup_lat: rfrom_lat,
            //                 return_pickup_lon: rfrom_lon,
            //                 return_drop_location: rdrop_location,
            //                 return_drop_lat: rto_lat,
            //                 return_drop_lon: rto_lon,
            //                 return_ride_date: moment(ReSchDate).format('YYYY-MM-DD'),
            //                 return_pick_up_time: ReSchTime,
            //                 return_promocode: "",
            //                 return_special_note: rcomment,
            //                 type: "Schedule",
            //                 vehicle_typeName: vehicle_name,
            //                 total_amount: Math.ceil(RValues.result.return_trip.amount),
            //                 total_distance: RValues.result.return_trip.total_distance,
            //                 total_minute: RValues.result.return_trip.total_minute,
            //                 extra_stops: RValues.result.return_trip.extra_stops,
            //                 extra_stop_status: RValues.result.return_trip.extra_stop_status,
            //                 per_stop_fare: RValues.result.return_trip.per_stop_price,
            //                 per_stop_increase_cap_fare: RValues.result.return_trip.per_stop_increase_price,
            //                 per_stop_multiple: RValues.result.return_trip.per_stop_multiple,
            //                 per_stop_increase_multiple: RValues.result.return_trip.per_stop_increase_multiple,
            //                 overall_extra_stop_cost: RValues.result.return_trip.overall_extra_stop_cost,
            //                 vehicle_type: vehicle_name,
            //                 today_date: moment(ReSchDate).format('YYYY-MM-DD'),
            //                 minimum_distance_fare: RValues.result.return_trip.minimum_fare,
            //                 minimum_distance_fare_status: RValues.result.return_trip.minimum_fare_status,
            //                 sub_total: RValues.result.return_trip.sub_total,
            //                 cap_price: RValues.result.return_trip.cap_price,
            //                 cap_status: RValues.result.return_trip.capped_status,
            //                 base_fare: RValues.result.return_trip.base_fare,
            //                 surge_price: RValues.result.return_trip.surgeprice.length > 0 ? surge : '[]',
            //                 addon_amount: RValues.result.return_trip.addon_amount,
            //                 addon_data: RValues.result.return_trip.addon_data.length > 0 ? RValues.result.return_trip.addon_data : ''
            //             }
            //         });

            //         toast.success('Booking added into cart', {
            //             position: "top-right",
            //             autoClose: 3000,
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //             theme: "colored",
            //         });

            //         setTimeout(() => {
            //             window.location.reload();
            //         }, 5000);
            //     } else {
            //         toast.error(RValues.message, {
            //             position: "top-right",
            //             autoClose: 3000,
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //             theme: "colored",
            //         });
            //         setCartButton(0)
            //         return false
            //     }
            // }
            if (ReturnDiv == 1) {
                const inputRdata = []
                var pick_up = {
                    lat: rfrom_lat,
                    long: rfrom_lon,
                    stop_name: rpickup_location,
                };
                inputRdata.push(pick_up)
                ReStopDivs.map((data, i) => {
                    inputRdata.push(data)
                })
                var drop = {
                    lat: rto_lat,
                    lon: rto_lon,
                    stop_name: rdrop_location
                }
                inputRdata.push(drop)
                var extra_stop = JSON.stringify(inputRdata)
                const RValues = await SchedulefrData()
                var surge = JSON.stringify(0)
                if (RValues.message == "success") {
                    let ride_time = rideTime(moment(ReSchDate).format('YYYY-MM-DD'), ReSchTime)
                    store.dispatch({
                        type: "addToCart",
                        payload: {
                            pick_up: rpickup_location,
                            pickup_lat: rfrom_lat,
                            pickup_lon: rfrom_lon,
                            date: moment(ReSchDate).format('YYYY-MM-DD'),
                            pick_up_time: ReSchTime,
                            // ride_time: ride_time,
                            flight_eta: "",
                            flight_number: "",
                            customer_id: "",
                            mode: "ridelater",
                            rider_type: 3,
                            user_type: 3,
                            trip_type: 3,
                            request_type: "web",
                            service_type: "normal",
                            ride_date: moment(ReSchDate).format('YYYY-MM-DD'),
                            flight_date: "",
                            flight_actual_arrival_time: "",
                            flight_carrier_code: "",
                            flight_code: "",
                            no_of_adults: "",
                            luggage: "",
                            wheel_chair: "",
                            pick_up_time: ReSchTime,
                            airport_type: "",
                            no_of_stops: inputRdata.length > 2 ? inputRdata.length - 2 : 0,
                            extra_stops: extra_stop,
                            // extra_stops: encodeURIComponent(extra_stop),
                            return_no_of_stops: inputRdata.length > 2 ? inputRdata.length - 2 : 0,
                            return_extra_stops: encodeURIComponent(extra_stop),
                            return_ride_time: "",
                            return_type: "return",
                            return_vehicle_type: vehicle_name,
                            return_vehicle_id: vehicle_type,
                            time_validate: "1",
                            drop_location: rdrop_location,
                            drop_lat: to_lat,
                            drop_lon: to_lon,
                            vehicle_id: vehicle_type,
                            short_name: "",
                            passenger_name: main_name,
                            passenger_phone_code: cc,
                            passenger_phone_number: cc + '' + phone,
                            promocode: "",
                            special_note: rcomment,
                            return_pick_up: rpickup_location,
                            return_pickup_lat: rfrom_lat,
                            return_pickup_lon: rfrom_lon,
                            return_drop_location: rdrop_location,
                            return_drop_lat: rto_lat,
                            return_drop_lon: rto_lon,
                            return_ride_date: moment(ReSchDate).format('YYYY-MM-DD'),
                            return_pick_up_time: ReSchTime,
                            return_promocode: "",
                            return_special_note: rcomment,
                            type: "Schedule",
                            vehicle_typeName: vehicle_name,
                            total_amount: Math.ceil(rdriver_payout_amount),
                            total_distance: 0,
                            total_minute: 0,
                            // extra_stops: 0,
                            extra_stop_status: inputRdata.length > 2 ? 1 : 0,
                            per_stop_fare: 0,
                            per_stop_increase_cap_fare: 0,
                            per_stop_multiple: 0,
                            per_stop_increase_multiple: 0,
                            overall_extra_stop_cost: 0,
                            vehicle_type: vehicle_name,
                            today_date: moment(ReSchDate).format('YYYY-MM-DD'),
                            minimum_distance_fare: 0,
                            minimum_distance_fare_status: 0,
                            sub_total: Math.ceil(rdriver_payout_amount),
                            cap_price: Math.ceil(rdriver_payout_amount),
                            cap_status: 0,
                            base_fare: 0,
                            surge_price: 0 > 0 ? surge : '[]',
                            addon_amount: 0,
                            addon_data: 0 > 0 ? 0 : ''
                        }
                    });

                    toast.success('Booking added into cart', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                } else {
                    toast.error(RValues.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setCartButton(0)
                    return false
                }
            }
        } else {
            setCartButton(0)
        }
    }

    const getCheck = async (token) => {
        const tokenMessage = await token;
        if (tokenMessage == 'sucessEmail') {
            setEmailVerify(1)
            toast.success('OTP verified successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

        } else if (tokenMessage == 'sucessMobile') {
            setPhoneVerify(1)
            toast.success('OTP verified successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }

    async function CountryCodeApi() {
        let data = {}
        await ApiCall('countrycode', data).then((data) => {
            if (data.success === "success") {
                setCountryCode(data.data)
            }
        }).catch(err => console.log(err));
    }

    const fareEstiamte = async () => {
        if (AirDate != '' && arrival_time != '' && flight_no != '' && drop_location != '' && Passenger != '' && Wheelchair != '' && Luggage != '' && vehicle_type != '') {
            const Values = await fareAPICall()
            if (Values.message == "success") {
                setFareData(Values.result)
            } else {
                toast.error(Values.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }

    const fareAPICall = async () => {
        const inputdata = []
        var pick_up = {
            lat: from_lat,
            long: from_lon,
            stop_name: pickup_location,
        };
        inputdata.push(pick_up)
        StopDivs.map((data, i) => {
            inputdata.push(data)
        })
        var drop = {
            lat: to_lat,
            lon: to_lon,
            stop_name: drop_location
        }
        inputdata.push(drop)
        var extra_stop = JSON.stringify(inputdata)
        let data = {
            customer_id: "",
            pickup_lat: from_lat,
            pickup_lon: from_lon,
            drop_lat: to_lat,
            drop_lon: to_lon,
            vehicle_id: vehicle_type,
            service_type: "airport",
            ride_date: ride_date,
            flight_eta: arrival_time,
            flight_number: flight_no,
            flight_date: flight_date,
            flight_carrier_code: flight_carrier_code,
            luggage: Luggage,
            flight_code: flight_code,
            flight_actual_arrival_time: flight_actual_arrival_time,
            ride_time: arrival_time,
            wheel_chair: Wheelchair,
            promocode: "",
            no_of_adults: Passenger,
            no_of_stops: inputdata.length > 2 ? inputdata.length - 2 : 0,
            extra_stops: encodeURIComponent(extra_stop),
            date: moment(AirDate).format('YYYY-MM-DD'),
            pick_up_time: arrival_time,
            guest_type: 1,
            addon_services: AddonData.length > 0 ? JSON.stringify(AddonData) : '',
        }
        var FareData = {};
        await ApiCall('guest_fare_estimate', data).then((data) => {
            FareData = data;
        }).catch(err => console.log(err));
        return FareData
    }

    useEffect(() => {
        CountryCodeApi()
        //clear localstorage
        localStorage.removeItem('stopPosition0')
        localStorage.removeItem('stopPosition1')
        localStorage.removeItem('pick_up')
        localStorage.removeItem('pickupPosition')
        localStorage.removeItem('drop')
        localStorage.removeItem('dropoffPosition')
        window.dispatchEvent(new Event("storage"));
    }, [])

    // airport return
    const ReturnSwitch = () => {
        if (ReturnDiv == 0) {
            setReturnDiv(1)
        } else {
            setReturnDiv(0)
        }
    }

    // return pick
    const rfromLoad = (autocomplete) => {
        setRFromPlaces(autocomplete)
    }

    const onrFromPlaceChanged = async () => {
        if (rfrom_places !== null) {
            var pickRLat = rfrom_places.getPlace().geometry.location.lat()
            var pickRLon = rfrom_places.getPlace().geometry.location.lng()
            var pickRAdd = rfrom_places.getPlace().formatted_address
            var pickRName = rfrom_places.getPlace().name

            setRFromLat(pickRLat)
            setRFromLon(pickRLon)
            setRPickupLocation(pickRAdd)
            // localstroage
            localStorage.setItem('pick_up', pickRName);
            const PickPosition = {
                lat: pickRLat,
                lng: pickRLon
            }
            localStorage.setItem('pickupPosition', JSON.stringify(PickPosition))
            window.dispatchEvent(new Event("storage"));
        }
    }

    // return drop
    const rtoLoad = (autocomplete) => {
        setRToPlaces(autocomplete)
    }

    const onrToPlaceChanged = async () => {
        if (rto_places !== null) {
            var dropRLat = rto_places.getPlace().geometry.location.lat()
            var dropRLon = rto_places.getPlace().geometry.location.lng()
            var dropRAdd = rto_places.getPlace().formatted_address
            var dropRName = rto_places.getPlace().name

            setRToLat(dropRLat)
            setRToLon(dropRLon)
            setRDropLocation(dropRAdd)
            // local storage
            localStorage.setItem('drop', dropRName);
            const DropPosition = {
                lat: dropRLat,
                lng: dropRLon
            }
            localStorage.setItem('dropoffPosition', JSON.stringify(DropPosition))
            window.dispatchEvent(new Event("storage"));
        }
    }

    const onReDateChange = (event) => {
        setReSchDate(event)
    }

    const onReTimeChange = (event) => {
        const timeValue = new Date(event);
        const formattedTime = moment(timeValue).format('hh:mm a');
        setReSchTime(formattedTime)
    }

    //Return Add Stop
    const ReRemoveStop = (i) => {
        const deletVal = [...ReStopDivs]
        deletVal.splice(i, 1)
        setReStopDivs(deletVal)
        // local storage
        localStorage.removeItem('stopPosition' + i)
        window.dispatchEvent(new Event("storage"));
    }

    const RinputRefs = useRef([]);
    const fromRhandleChange = (ref, index) => {
        inputRefs.current[index] = ref;
    }

    const RehandleChange = async (place, i) => {
        const inputdata = [...ReStopDivs]
        if (place) {
            var stopLat = place.geometry.location.lat()
            var stopLon = place.geometry.location.lng()
            inputdata[i] = {
                lat: stopLat,
                long: stopLon,
                stop_name: place.formatted_address,
            };
        }
        setReStopDivs(inputdata)
        // local storage
        const StopPosition = {
            lat: stopLat,
            lng: stopLon
        }
        localStorage.setItem('stopPosition' + i, JSON.stringify(StopPosition))
        window.dispatchEvent(new Event("storage"));
    }

    const ReAddStop = () => {
        const data = [...ReStopDivs, []]
        setReStopDivs(data)
    }

    let timeProps = {
        placeholder: 'Time',
        disabled: true,
    };

    let timePropsE = {
        placeholder: 'Time',
        disabled: false
    }


    const [fareReData, setFareReData] = useState([])
    const [FareTotalAmount, setFareTotalAmount] = useState(0)

    async function ScheduleFareReturn() {
        if (rpickup_location != '' && rdrop_location != '' && ReSchDate != '' && ReSchTime != '' && vehicle_type != '') {
            const Values = await SchedulefrData()
            if (Values.message == "success") {
                if (Values.result.return_trip.capped_status == 1 && Values.result.return_trip.extra_stop_status == 1) {
                    setFareTotalAmount(Math.ceil(Values.result.return_trip.amount))
                } else if (Values.result.return_trip.capped_status == 1 && Values.result.return_trip.surgeprice.length > 0) {
                    setFareTotalAmount(Math.ceil(Values.result.return_trip.amount))
                } else if (Values.result.return_trip.capped_status == 1) {
                    setFareTotalAmount(Math.ceil(Values.result.return_trip.cap_price))
                } else {
                    setFareTotalAmount(Math.ceil(Values.result.return_trip.amount))
                }
                setFareReData(Values.result.return_trip)
            } else {
                toast.error(Values.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }

    const SchedulefrData = async () => {

        const inputRdata = []
        var pick_up = {
            lat: rfrom_lat,
            long: rfrom_lon,
            stop_name: rpickup_location,
        };
        inputRdata.push(pick_up)
        ReStopDivs.map((data, i) => {
            inputRdata.push(data)
        })
        var drop = {
            lat: rto_lat,
            lon: rto_lon,
            stop_name: rdrop_location
        }
        inputRdata.push(drop)
        var extra_stop = JSON.stringify(inputRdata)

        let data = {
            customer_id: "",
            pickup_lat: from_lat,
            pickup_lon: from_lon,
            drop_lat: to_lat,
            drop_lon: to_lon,
            vehicle_id: vehicle_type,
            service_type: "airport",
            ride_date: moment(AirDate).format('YYYY-MM-DD'),
            ride_time: arrival_time,
            flight_eta: arrival_time,
            flight_number: flight_no,
            flight_date: flight_date,
            flight_carrier_code: flight_carrier_code,
            luggage: Luggage,
            flight_code: flight_code,
            flight_actual_arrival_time: flight_actual_arrival_time,
            wheel_chair: Wheelchair,
            no_of_adults: Passenger,
            promocode: "",
            return_type: "return",
            return_vehicle_id: vehicle_type,
            return_pickup_lat: rfrom_lat,
            return_pickup_lon: rfrom_lon,
            return_drop_lat: rto_lat,
            return_drop_lon: rto_lon,
            time_validate: "1",
            return_ride_date: moment(ReSchDate).format('YYYY-MM-DD'),
            return_ride_time: ReSchTime,
            return_promocode: "",
            return_no_of_stops: inputRdata.length > 2 ? inputRdata.length - 2 : 0,
            return_extra_stops: encodeURIComponent(extra_stop),
            date: moment(ReSchDate).format('YYYY-MM-DD'),
            pick_up_time: ReSchTime,
            guest_type: 1,
            return_addon_services: ReAddonData.length > 0 ? JSON.stringify(ReAddonData) : '',
        }
        var FareData = {};
        await ApiCall('guest_fare_estimate', data).then((data) => {
            FareData = data;
        }).catch(err => console.log(err));

        return FareData
    }

    useEffect(() => {
        setTimeout(() => {
            fareEstiamte()
        }, 1000);
    }, [AirDate, arrival_time, flight_no, drop_location, Passenger, Wheelchair, Luggage, vehicle_type, StopDivs, AddonData])

    useEffect(() => {
        setTimeout(() => {
            ScheduleFareReturn()
        }, 1000);
    }, [rpickup_location, rdrop_location, ReSchDate, ReSchTime, vehicle_type, ReStopDivs, ReAddonData])

    useEffect(() => {
        // setvehDiv(0)
        setVehicleType("");
        setVehicleName("")
        setVehicleoptions([
            { value: '', name: 'vehicle_type', text: 'car', label: <div>Vehicle Type </div> }
        ])
        ChooseVeh()
    }, [Passenger, Wheelchair, Luggage])

    useEffect(() => {
        setchangeflightDiv(0)
    }, [flight_no, AirDate, arrival_time])

    return (
        <>
            <div className='formDiv' id="airportBook">
                <p style={{ fontWeight: 'bold', fontSize: '14px' }}>***Note: Check flight details to proceed further</p>
                <form autoComplete='off'>

                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-12 mb-2'>
                            <div className='d-flex justify-content-between'>
                                <h5 className=''>Booking Details</h5>
                                <i class="fa-solid fa-circle-info" data-tooltip-id="tooltip-buffer"></i>
                                <ReactTooltip id="tooltip-buffer" place="top">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>Time for the driver to reach</span>
                                        <span>airport after your flight lands</span>
                                    </div>
                                </ReactTooltip>
                            </div>
                            {/* <hr /> */}

                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <div className="inner-addon right-addon">
                                        {/* <label className="form-label">Select Pickup Date</label> */}
                                        <DatePicker
                                            selected={AirDate}
                                            onChange={onDateChange}
                                            dateFormat='yyyy-MM-dd'
                                            minDate={new Date}
                                            className='form-control'
                                            placeholderText='Select Pickup Date'
                                        />
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.date}</span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="">
                                        {/* <label className="form-label">Additional Buffer (minutes)</label> */}
                                        <select className="form-select rounded-pill" name="arrival_time" onChange={inputChange}>
                                            <option value="" selected>Choose Buffer Minutes</option>
                                            <option value="0 mins">0 MIN</option>
                                            <option value="10 mins">10 MINS</option>
                                            <option value="20 mins">20 MINS</option>
                                            <option value="30 mins">30 MINS</option>
                                            <option value="40 mins">40 MINS</option>
                                            <option value="50 mins">50 MINS</option>
                                            <option value="60 mins">60 MINS</option>
                                            <option value="70 mins">70 MINS</option>
                                            <option value="80 mins">80 MINS</option>
                                            <option value="90 mins">90 MINS</option>
                                            <option value="100 mins">100 MINS</option>
                                            <option value="110 mins">110 MINS</option>
                                            <option value="120 mins">120 MINS</option>
                                        </select>
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.arrival}</span>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-1'>
                                <div className='col-lg-6 col-12'>
                                    <div className="">
                                        {/* <label className="form-label">Please Enter your Flight Number</label> */}
                                        <input type="text" className="form-control rounded-pill" placeholder='Your Flight Number' name='flight_number' onChange={inputChange} />
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.flight}</span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="">
                                        {/* <label className="form-label">&nbsp;</label> */}
                                        <input type="button" className="form-control btn rounded-pill billCart" value='Check Flight Details' onClick={checkFlight} />
                                    </div>
                                </div>
                            </div>

                            {flightDiv == 1 && changeflightDiv == 1 ? <div className="row ps-2 pe-2 mb-2 justify-content-center" id="flight_data">
                                <div className="col-12 col-lg-12">
                                    <div className="row flight-details align-items-center">
                                        <div className="col-2 col-lg-1">
                                            <i className="fa fa-plane" aria-hidden="true" style={{ fontSize: '30px', color: '#63881e' }}></i>
                                        </div>
                                        <div className="ms-1 col-8 col-lg-9">
                                            <div>
                                                <span>Flight Number</span>:&nbsp;<span id="f_number">{flight_no}</span><br />
                                                <span>Scheduled Date/Time</span>:&nbsp;<span id="f_date_time">{flight_data.arrivalTime}</span><br />
                                            </div>
                                        </div>
                                        <div className="col-2 col-lg-1">
                                            <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: '30px', color: '#63881e' }}></i>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}

                            <div className="" style={{ display: 'none' }}>
                                {/* <label className="form-label">Pickup Location</label> */}
                                <input type="text" className="form-control rounded-pill" id="pickup" name="pickup" placeholder='Pickup Location' />
                            </div>

                            {StopDivs.map((data, i) => {
                                return (
                                    <div>
                                        <div className="row mb-1">
                                            <div className="col-lg-11 col-10">
                                                <Autocomplete onPlaceChanged={() => handleChange(inputRefs.current[i]?.getPlace(), i)} onLoad={(autocomplete) => fromhandleChange(autocomplete, i)} options={AutocompleteRestrict}>
                                                    <input type="text" className="form-control rounded-pill" placeholder={`Stop ${i + 1}`} disabled={flightDiv == 0 ? true : false} />
                                                </Autocomplete>
                                            </div>
                                            <div className="col-lg-1 col-2 d-flex align-items-center">
                                                <i className="fa fa-times-circle" onClick={() => RemoveStop(i)}></i>
                                            </div>
                                        </div>
                                        <span className="error-field text-dark mb-1" style={{ marginLeft: "0.5rem" }}>{formError['stop' + (i + 1)]}</span>
                                    </div>
                                )
                            })}

                            <div className={flightDiv == 0 ? 'ReturnDiv' : ''}>
                                {/* <div className="text-end">
                                    <input type="button" className="btn rounded-pill billCart" value='Add Stop' onClick={AddStop} disabled={flightDiv == 0 || StopDivs.length == 2 ? true : false} />
                                </div> */}

                                <div className="row mb-1">
                                    <div className='col-lg-9'>
                                        {/* <label className="form-label">Drop Location</label> */}
                                        <Autocomplete onLoad={toLoad} onPlaceChanged={onToPlaceChanged} options={AutocompleteRestrict}>
                                            <input type="text" className="form-control rounded-pill" id="drop" name="drop" placeholder='Drop Location' disabled={flightDiv == 0 ? true : false} />
                                        </Autocomplete>
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.drop}</span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <input type="button" className="btn rounded-pill billCart" value='Add Stop' onClick={AddStop} disabled={flightDiv == 0 || StopDivs.length == 2 ? true : false} />
                                    </div>
                                </div>

                                {/* <h5 className='mb-1'>Passenger Details</h5> */}
                                <div className="passenger-dtls mb-2">
                                    <div className="row mb-1">
                                        <div className="col-4">
                                            <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>No of Passengers</span>
                                            <div className="buttons d-flex align-items-center">
                                                <p className='airport-decrement-btn' id="DecP" onClick={passengerOut} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; - &nbsp;</p>
                                                <span className="number_padd" id="passenger-input">{Passenger}</span>
                                                <p className='airport-increment-btn' id="DecP" onClick={passengerIn} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; + &nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>No of Wheelchairs</span>
                                            <div className="buttons d-flex align-items-center">
                                                <p className='airport-decrement-btn' id="DecP" onClick={wheelchairOut} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; - &nbsp;</p>
                                                <span className="number_padd" id="wheelchair-input">{Wheelchair}</span>
                                                <p className='airport-increment-btn' id="DecP" onClick={wheelchairIn} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; + &nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <span className="no_of" style={{ color: 'white', fontSize: '14px' }}>No of Luggages</span>
                                            <div className="buttons d-flex align-items-center">
                                                <p className='airport-decrement-btn' id="DecP" onClick={luggageOut} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; - &nbsp;</p>
                                                <span className="number_padd" id="luggage-input">{Luggage}</span>
                                                <p className='airport-increment-btn' id="DecP" onClick={luggageIn} style={flightDiv == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}> &nbsp; + &nbsp;</p>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-3 col-md-6 col-sm-8'>
                                            <label className="form-label" style={{ fontSize: '1px' }}>&nbsp;</label>
                                            <input type="button" className="form-control btn rounded-pill billCart" value='Choose Your Vehicle' disabled={flightDiv == 0 ? true : false} onClick={ChooseVeh} />
                                        </div> */}
                                    </div>

                                </div>

                                {/* <div className='text-center mb-1'>
                                    <input type="button" className="btn rounded-pill billCart" value='Choose Your Vehicle' disabled={flightDiv == 0 ? true : false} onClick={ChooseVeh} />
                                </div> */}
                            </div>

                            <div className={flightDiv == 0 ? 'ReturnDiv' : ''}>
                                {/* <select className="form-select rounded-pill" aria-label="Default select example" disabled={vehDiv == 0 ? true : false}>
                        <option selected>Vehicle Type</option>
                    </select> */}
                                <div className="row mt-2">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <Select
                                            styles={Selectstyles}
                                            options={Vehicleoptions}
                                            placeholder="Vehicle Type"
                                            onChange={selectChange}
                                            isDisabled={flightDiv == 0 ? true : false}
                                            value={Vehicleoptions.filter(function (option) {
                                                return option.value === vehicle_type;
                                            })}
                                        />
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.vehicle}</span>
                                    </div>
                                    <div className={AddonShow == 1 ? 'col-lg-4 col-md-4 col-12' : 'd-none'}>
                                        {/* <span className="no_of" style={{ color: 'white' }}>&nbsp;</span> */}
                                        <div class="form-check d-flex justify-content-between">
                                            {/* <div className=''>
                                                <input class="form-check-input" type="checkbox" id="addon" name="addon" onChange={AddonModelOpen} checked={AddonData.length > 0 ? true : false} />
                                                <label class="form-check-label">
                                                    Add-on Services
                                                </label>
                                                <i className='fa fa-circle-info ms-1' data-tooltip-id="tooltip-addon"></i>
                                            </div> */}
                                            <p className={AddonData.length > 0 ? 'text-end addon-edit' : 'text-end addon-edit d-none'} onClick={AddonModelEdit}>Edit</p>

                                            <ReactTooltip id="tooltip-addon" place="top">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {AddonData.length > 0 ? AddonData.map((item, key) => {
                                                        return (
                                                            <span>{item.name} - {item.count}</span>
                                                        )
                                                    }) : <><p>No add-on selected</p></>}
                                                </div>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="confirnBook p-2 mb-1">
                                <p className="text-center confirm-title pt-3">Fare Breakdown</p>
                                <div className='row justify-content-center' id="surge-div">
                                    <div className="col-md-11 col-11 pt-1">
                                        <div className='row'>
                                            <div className={fareData != '' && (fareData.surgeprice.length > 0 || fareData.addon_data.length > 0) ? 'col-lg-6 col-12 checkout p-1' : 'd-none'}>
                                                <div className="container" id="fare-scroll">
                                                    <table id="fare-table">
                                                        {fareData != '' ? <>
                                                            {fareData.surgeprice.length > 0 ? fareData.surgeprice.map((data, i) => {
                                                                return (<>
                                                                    <tr>
                                                                        <td style={{ fontSize: '14px' }}>{data.name}</td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.amount).toFixed(2)}</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3}><hr /></td>
                                                                    </tr></>)
                                                            }) : <></>}</> : <></>
                                                        }
                                                        {fareData != '' ? <>
                                                            {fareData.addon_data.length > 0 ? fareData.addon_data.map((data, i) => {
                                                                return (<><tr>
                                                                    <td style={{ fontSize: '14px' }}>{data.name}</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.amount).toFixed(2)}</p>
                                                                    </td>
                                                                </tr>
                                                                    <tr>
                                                                        <td colSpan={3}><hr /></td>
                                                                    </tr></>)
                                                            }) : <></>}</> : <></>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={fareData != '' && fareData.surgeprice.length > 0 ? 'col-lg-6 col-12' : 'col-12'}>
                                                <div className="row justify-content-center" id="air-div">
                                                    <div className="col-md-12 py-2">
                                                        <div className="row">
                                                            <div className="col-6 text-center dist-fare">
                                                                <p className="dist-fare-head">Base Fare</p>
                                                                {fareData != '' ? <p className="mb-0 dist-charge" id="m-fare-base"><span>S$</span> {parseFloat(fareData.base_fare).toFixed(2)}</p> : <p className="mb-0 dist-charge" id="m-fare-base"><span>S$</span> {parseFloat(0).toFixed(2)}</p>}
                                                            </div>
                                                            <div className="col-6 text-center" id="air-sub">
                                                                <p className="dist-fare-head">Sub Total</p>
                                                                {fareData != '' ? <>
                                                                    {fareData.capped_status == 1 ? <>
                                                                        <p className="mb-0 dist-charge" id="fare-sub" style={{ textDecoration: 'line-through' }}><span>S$</span> {parseFloat(fareData.sub_total).toFixed(2)}</p>
                                                                        <p className="mb-0 dist-charge" id="fare-sub-cap"><span>S$</span> {parseFloat(fareData.cap_price).toFixed(2)}</p>
                                                                    </> : <>
                                                                        <p className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(fareData.sub_total).toFixed(2)}</p>
                                                                    </>}</> : <>
                                                                    <p className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(0).toFixed(2)}</p></>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {fareData.extra_stop_status == 1 ? <>
                                    <div id="stopFare-div-air">
                                        {/* <div className="row justify-content-center">
                                            <div className="col-10">
                                                <hr className="fare-hr" />
                                            </div>
                                        </div> */}
                                        {/* <div className="row justify-content-center">
                                            <div className="col-md-10 col-10">
                                                <div className='d-flex justify-content-center fare-total'>Extra Stop Fare : S$&nbsp;&nbsp;{parseFloat(fareData.overall_extra_stop_cost).toFixed(2)}</div> */}
                                                {/* <div className="row">
                                                    <div className="col-5 p-0" style={{ color: '#212021' }}>Extra Stop Fare</div>
                                                    <div className="col-1" style={{ color: '#212021' }}>:</div>
                                                    <div className="col-4 p-0" style={{ color: '#212021' }}>
                                                        <span id="extraStopText-air">{parseFloat(0).toFixed(2)}</span>&nbsp;&nbsp;SGD
                                                    </div>
                                                </div> */}
                                            {/* </div>
                                        </div>
                                    </div>
                                </> : <></>} */}
                                {/* <div className="row justify-content-center">
                                    <div className="col-10">
                                        <hr className="fare-hr" />
                                    </div>
                                </div> */}
                                {/* <div className="row justify-content-center">
                                    <div className="col-md-10 col-12">
                                        <div className='d-flex justify-content-center fare-total'>Total Price : {fareData != '' ? <>S$&nbsp;&nbsp;{parseFloat(Math.ceil(fareData.amount)).toFixed(2)}</> : <>S$&nbsp;&nbsp;{parseFloat(Math.ceil(0)).toFixed(2)}</>}</div> */}
                                        {/* <div className="row">
                                    <div className="col-5 fare-total p-0">
                                        Total Price
                                    </div>
                                    <div className="col-1 fare-total">
                                        :
                                    </div>
                                    <div className="col-6 fare-list1 p-0">
                                        {fareData != '' ? <><span id="ma-fare-total">{parseFloat(Math.ceil(fareData.amount)).toFixed(2)} </span>&nbsp;&nbsp;SGD</> : <><span id="ma-fare-total">{parseFloat(Math.ceil(0)).toFixed(2)} </span>&nbsp;&nbsp;SGD</>}
                                    </div>
                                </div> */}
                                    {/* </div>
                                </div>
                            </div> */}

                            <div className="form-group mb-3 amount">
                                <input type='number' className="form-control"  placeholder="Enter driver payout amount" name='amount' id="amount" onChange={inputChange} min="0" value={driver_payout_amount}/>
                                <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.amount}</span>
                            </div>
                            <div className="form-group mb-3 comment">
                                <textarea className="form-control" rows="1" placeholder="Comments if anything" name='comment' onChange={inputChange}></textarea>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12 mt-1'>
                            <div className="d-flex justify-content-between">
                                <h5>Return Trip Booking</h5>
                                <label className="switch">
                                    <input type="checkbox" id="returnCheck" onChange={ReturnSwitch} />
                                    <span className="slider round"></span>
                                </label>
                            </div>

                            <div className={ReturnDiv == 0 ? 'ReturnDiv' : ''}>
                                <div className='row mb-2'>
                                    {/* <p>Pick Date/Time</p> */}
                                    <div className='col-lg-6 col-12 mt-1'>
                                        <div className="inner-addon right-addon">
                                            <i className="glyphicon glyphicon-user fa fa-calendar textColor"></i>
                                            {/* <input type="text" className="form-control rounded-pill" placeholder='Date'/> */}
                                            <DatePicker
                                                selected={ReSchDate}
                                                onChange={onReDateChange}
                                                dateFormat='yyyy-MM-dd'
                                                minDate={new Date}
                                                className='form-control'
                                                placeholderText='Date'
                                                name='returnScheduleDate'
                                                disabled={ReturnDiv == 0 ? true : false} />
                                            <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.rdate}</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-12 mt-1'>
                                        <div className="inner-addon right-addon">
                                            <i className="glyphicon glyphicon-user fa fa-clock textColor"></i>
                                            {/* <input type="text" className="form-control rounded-pill" placeholder='Time'/> */}
                                            <Datetime dateFormat={false}
                                                inputProps={ReturnDiv == 0 ? timeProps : timePropsE}
                                                onChange={onReTimeChange}
                                                timeConstraints={timeConstraints}
                                                // value={ReSchTime}
                                                name='returnScheduleTime'
                                            />
                                            <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.rtime}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className='col-lg-9'>
                                        {/* <label className="form-label">Pickup Location</label> */}
                                        <Autocomplete onLoad={rfromLoad} onPlaceChanged={onrFromPlaceChanged} options={AutocompleteRestrict}>
                                            <input type="text" className="form-control rounded-pill" placeholder='Pickup Location' disabled={ReturnDiv == 0 ? true : false} defaultValue={ReturnDiv == 0 ? '' : drop_name} />
                                        </Autocomplete>
                                        <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.rpickup}</span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <input type="button" className="btn rounded-pill billCart" disabled={ReturnDiv == 0 || ReStopDivs.length == 2 ? true : false} value='Add Stop' onClick={ReAddStop} />
                                    </div>
                                </div>

                                {ReStopDivs.map((data, i) => {
                                    return (
                                        <div>
                                            <div className="row mb-2">
                                                <div className="col-lg-11 col-10">
                                                    <Autocomplete onPlaceChanged={() => RehandleChange(inputRefs.current[i]?.getPlace(), i)} onLoad={(autocomplete) => fromRhandleChange(autocomplete, i)} options={AutocompleteRestrict}>
                                                        <input type="text" className="form-control rounded-pill" placeholder={`Return Stop ${i + 1}`} disabled={ReturnDiv == 0 ? true : false} />
                                                    </Autocomplete>
                                                </div>
                                                <div className="col-lg-1 col-2 d-flex align-items-center">
                                                    <i className="fa fa-times-circle" onClick={() => ReRemoveStop(i)}></i>
                                                </div>
                                            </div>
                                            <span className="error-field text-dark mb-3" style={{ marginLeft: "0.5rem" }}>{formError['rstop' + (i + 1)]}</span>
                                        </div>
                                    )
                                })}

                                {/* <div className="text-end">
                                    <input type="button" className="btn rounded-pill billCart" disabled={ReturnDiv == 0 || ReStopDivs.length == 2 ? true : false} value='Add Stop' onClick={ReAddStop} />
                                </div> */}

                                <div className="mb-1">
                                    {/* <label className="form-label">Drop Location</label> */}
                                    <Autocomplete onLoad={rtoLoad} onPlaceChanged={onrToPlaceChanged} options={AutocompleteRestrict}>
                                        <input type="text" className="form-control rounded-pill" placeholder='Drop Location' disabled={ReturnDiv == 0 ? true : false} defaultValue={ReturnDiv == 0 ? '' : pick_name} />
                                    </Autocomplete>
                                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.rdrop}</span>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        {/* <label className="form-label">Vehicle Type</label> */}
                                        <input type="text" className="form-control rounded-pill" value={ReturnDiv == 0 ? '' : vehicle_name} disabled placeholder='Vehicle Type' />
                                    </div>
                                    <div className={AddonShow == 1 ? 'col-lg-4 col-md-4 col-12' : 'd-none'}>
                                        {/* <span className="no_of" style={{ color: 'white' }}>&nbsp;</span> */}
                                        <div class="form-check d-flex justify-content-between">
                                            {/* <div>
                                                <input class="form-check-input" type="checkbox" id="addon" name="addon" onChange={AddonModelOpenRe} disabled={ReturnDiv == 0 ? true : false} checked={ReAddonData.length > 0 ? true : false} />
                                                <label class="form-check-label">
                                                    Add-on Services
                                                </label>
                                                <i className='fa fa-circle-info ms-1' data-tooltip-id="tooltip-addon-return"></i>
                                            </div> */}
                                            <p className={ReAddonData.length > 0 ? 'text-end addon-edit' : 'text-end addon-edit d-none'} onClick={AddonModelEditRe}>Edit</p>
                                            <ReactTooltip id="tooltip-addon-return" place="top">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {ReAddonData.length > 0 ? ReAddonData.map((item, key) => {
                                                        return (
                                                            <span>{item.name} - {item.count}</span>
                                                        )
                                                    }) : <><p>No add-on selected</p></>}
                                                </div>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="confirnBook p-2 mb-3">
                                    <p className="text-center confirm-title pt-3">Fare Breakdown</p>
                                    <div className="row justify-content-center">
                                        <div className="col-md-11 col-11 pt-1">
                                            <div className='row'>
                                                <div className='col-lg-6 col-12 checkout p-1'>
                                                    <div className="container surge_con" id={fareReData != '' && (fareReData.surgeprice.length > 0 || fareReData.addon_data.length > 0) ? 'fare-scroll' : ''}>
                                                        <table id="fare-table">
                                                            {fareReData != '' ? <>
                                                                <tr>
                                                                    <td style={{ fontSize: '14px' }}>Base Fare</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(fareReData.base_fare).toFixed(2)}</p>
                                                                    </td>
                                                                </tr>
                                                                {fareReData.surgeprice.length > 0 ? fareReData.surgeprice.map((data, i) => {
                                                                    return (<>
                                                                        <tr>
                                                                            <td style={{ fontSize: '14px' }}>{data.name}</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.surge_amount).toFixed(2)}</p>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={3}><hr /></td>
                                                                        </tr></>)
                                                                }) : <></>}</> : <>
                                                                <tr>
                                                                    <td style={{ fontSize: '14px' }}>Base Fare</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(0).toFixed(2)}</p>
                                                                    </td>
                                                                </tr></>
                                                            }
                                                            {fareReData != '' ? <>
                                                                {fareReData.addon_data.length > 0 ? fareReData.addon_data.map((data, i) => {
                                                                    return (<><tr>
                                                                        <td style={{ fontSize: '14px' }}>{data.name}</td>
                                                                        <td>:</td>
                                                                        <td>
                                                                            <p className='m-0 p-0 dist-charge'><span>S$</span> {parseFloat(data.amount).toFixed(2)}</p>
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td colSpan={3}><hr /></td>
                                                                        </tr></>)
                                                                }) : <></>}</> : <></>
                                                            }
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-12'>
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-12 px-4">
                                                            <div className="row">
                                                                <table cellSpacing={3}>
                                                                    <tr>
                                                                        <td className='dist-fare-head'>Distance Fare</td>
                                                                        {fareReData != '' ? <>
                                                                            <td className="mb-0 dist-charge" id="fare-distance"><span>S$</span> {parseFloat(fareReData.distance_price).toFixed(2)}</td>
                                                                            <td className="mb-0 dist-time" id="fare-distance-text">( {fareReData.total_distance} KMS )</td></> : <>
                                                                            <td className="mb-0 dist-charge" id="fare-distance"><span>S$</span> {parseFloat(0).toFixed(2)}</td>
                                                                            <td className="mb-0 dist-time" id="fare-distance-text">( 0 KMS )</td></>}
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='dist-fare-head'>Time Duartion</td>
                                                                        {fareReData != '' ? <>
                                                                            <td className="mb-0 dist-charge" id="fare-min"><span>S$</span> {parseFloat(fareReData.duration_price).toFixed(2)}</td>
                                                                            <td className="mb-0 dist-time" id="fare-min-text">( {fareReData.total_minute} Mins )</td></> : <>
                                                                            <td className="mb-0 dist-charge" id="fare-min"><span>S$</span> {parseFloat(0).toFixed(2)}</td>
                                                                            <td className="mb-0 dist-time" id="fare-min-text">( 0 Mins )</td></>}
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='dist-fare-head'>Sub Total</td>
                                                                        {fareReData != '' ? <>
                                                                            {fareReData.capped_status == 1 ? <>
                                                                                <td className="mb-0 dist-charge" id="fare-sub" style={{ textDecoration: 'line-through' }}><span>S$</span>{parseFloat(fareReData.sub_total).toFixed(2)}</td>
                                                                                <td className="mb-0 dist-charge" id="fare-sub-cap"><span>S$</span> {parseFloat(fareReData.cap_price).toFixed(2)} <span>(Capped)</span></td>
                                                                                <td className="mb-0 dist-time">Approx</td>
                                                                            </> : <>
                                                                                <td className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(fareReData.sub_total).toFixed(2)}</td>
                                                                            </>}
                                                                        </> : <><td className="mb-0 dist-charge" id="fare-sub"><span>S$</span> {parseFloat(0).toFixed(2)}</td></>}
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* extra stop cost */}

                                    {/* {fareReData != '' && fareReData.extra_stop_status == 1 ? <>
                                        <div id="stopFare-div"> */}
                                            {/* <div className="row justify-content-center">
                                                <div className="col-10">
                                                    <hr className="fare-hr" />
                                                </div>
                                            </div> */}
                                            {/* <div className="row justify-content-center">
                                                <div className="col-md-10 col-10 mt-2">
                                                    <div className='d-flex justify-content-center fare-total'>Extra Stop Fare : S$&nbsp;&nbsp;{parseFloat(fareReData.overall_extra_stop_cost).toFixed(2)}</div> */}
                                                    {/* <div className="row">
                                                        <div className="col-5 p-0" style={{ color: '#212021' }}>Extra Stop Fare</div>
                                                        <div className="col-1" style={{ color: '#212021' }}>:</div>
                                                        <div className="col-4 p-0" style={{ color: '#212021' }}>
                                                            <span id="extraStopText">{parseFloat(fareReData.overall_extra_stop_cost).toFixed(2)} </span>&nbsp;&nbsp;SGD
                                                        </div>
                                                    </div> */}
                                                {/* </div>
                                            </div>
                                        </div></> : <></>} */}


                                    {/* <div className="row justify-content-center">
                                        <div className="col-10">
                                            <hr className="fare-hr" />
                                        </div>
                                    </div> */}
                                    {/* <div className="row justify-content-center">
                                        <div className="col-md-10 col-12 mt-2">
                                            <div className='d-flex justify-content-center fare-total'>Total Price : S$&nbsp;&nbsp;{parseFloat(FareTotalAmount).toFixed(2)}</div> */}
                                            {/* <div className="row">
                                        <div className="col-5 fare-total p-0">
                                            Total Price
                                        </div>
                                        <div className="col-1 fare-total">
                                            :
                                        </div>
                                        <div className="col-6 fare-list1 p-0">
                                            <span id="fare-total">{parseFloat(FareTotalAmount).toFixed(2)} </span>&nbsp;&nbsp;SGD
                                        </div>
                                    </div> */}
                                        {/* </div>
                                    </div>
                                </div> */}
                                <div className="form-group mb-3 amount">
                                    <input type='number' className="form-control"  placeholder="Enter driver payout amount" name='ramount' id="ramount" min="0" disabled={ReturnDiv == 0 ? true : false} onChange={inputChange} value={rdriver_payout_amount}/>
                                    <span className="error-field text-dark" style={{ marginLeft: "0.5rem" }}>{formError.ramount}</span>
                                </div>
                                <div className="form-group mb-3 comment">
                                    <textarea className="form-control" rows="1" placeholder="Comments if anything" id="rcomment" name="rcomment" disabled={ReturnDiv == 0 ? true : false}></textarea>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="text-end">
                        {cart_button == 1 ? <div className='d-flex justify-content-end'><ThreeDots
                            height="40"
                            width="40"
                            radius="9"
                            color="#ff8901"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        /></div> :
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button type="submit" className="btn rounded-pill billCart" onClick={AirportNow}>Add Cart</button>
                                {cartData.length > 0 ? <input type="button" className="btn rounded-pill billCart" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-backdrop="false" value={"View Cart (" + cartData.length + ")"} /> : ''}
                            </div>}
                    </div>

                </form>
            </div>

            {/* verify button */}
            <VerifyModel email={email} phone={cc + '' + phone} getSuccess={getCheck} />
            <div className='d-none'>
                <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#VerifyEmail" id="verifyEmail" value='' />
            </div>
            <div className='d-none'>
                <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#VerifyPhone" id="verifyPhone" value='' />
            </div>

            {/* model for fare estimate */}
            <div>
                <div className='d-none'>
                    <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#estimateModal" id="EstimateModal" data-bs-backdrop="false" />
                </div>
                <div className="modal fade" id="estimateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content confirnBook">
                            <div className="d-flex justify-content-end confirnClose">
                                <i className="fa fa-times-circle" data-bs-dismiss="modal" aria-label="Close"></i>
                            </div>
                            <p className="text-center confirm-title">Fare Breakdown</p>
                            <div className="row justify-content-center mt-4">
                                <div className="col-10">
                                    <div className="d-flex">
                                        <div className="pick-circle"></div>
                                        <p className="from-locat">From Location</p>
                                    </div>
                                    <p className="from-addr" id="ma-fare_pick">{pickup_location}</p>
                                    <hr />


                                    {StopDivs.length != 0 ? <>
                                        {StopDivs.map((data, i) => {
                                            return (
                                                <div id="stop1-div-air">
                                                    <div className="d-flex">
                                                        <div className="pick-circle"></div>
                                                        <p className="from-locat">Stop {i + 1}</p>
                                                    </div>
                                                    <p className="from-addr" id="stop1-addPi-air">{data.stop_name}</p>
                                                    <hr />
                                                </div>
                                            )
                                        })}
                                    </> : <><p>hsdi</p></>}

                                    <div className="d-flex">
                                        <div className="drop-circle"></div>
                                        <p className="to-locat">To Location</p>
                                    </div>
                                    <p className="to-addr" id="ma-fare_drop">{drop_location}</p>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-11 px-4 pt-3 fare-head">
                                    <p id="ma-fare-category">{vehicle_name}</p>
                                </div>
                            </div>

                            <div className="row justify-content-center" id="surge-div">
                                <div className="col-md-10 col-11 px-4 pt-4 checkout">
                                    <div className="container" id={fareData != '' && fareData.surgeprice.length > 0 ? 'ma-fare-scroll' : 'd-none'}>
                                        <table id="ma-fare-table">
                                            {fareData != '' ? <>
                                                {fareData.surgeprice.length > 0 ? fareData.surgeprice.map((data, i) => {
                                                    return (<>
                                                        <tr>
                                                            <td>{data.name}</td>
                                                            <td>:</td>
                                                            <td>
                                                                <p className='mb-0 dist-charge'>{parseFloat(data.amount).toFixed(2)}<span>SGD</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}><hr /></td>
                                                        </tr></>)
                                                }) : <></>}</> : <></>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>


                            <div className="row justify-content-center" id="air-div">
                                <div className="col-md-12 px-4 py-4">
                                    <div className="row">
                                        <div className="col-6 text-center dist-fare">
                                            <p className="dist-fare-head">Base Fare</p>
                                            <p className="mb-0 dist-charge" id="m-fare-base"> {parseFloat(fareData.base_fare).toFixed(2)} <span>SGD</span></p>
                                        </div>
                                        <div className="col-6 text-center" id="air-sub">
                                            <p className="dist-fare-head">Sub Total</p>
                                            {fareData != '' ? <>
                                                {fareData.capped_status == 1 ? <>
                                                    <p className="mb-0 dist-charge" id="fare-sub" style={{ textDecoration: 'line-through' }}> {parseFloat(fareData.sub_total).toFixed(2)} <span>SGD</span></p>
                                                    <p className="mb-0 dist-charge" id="fare-sub-cap"> {parseFloat(fareData.cap_price).toFixed(2)} <span> SGD</span></p>
                                                </> : <>
                                                    <p className="mb-0 dist-charge" id="fare-sub"> {parseFloat(fareData.sub_total).toFixed(2)} <span>SGD</span></p>
                                                </>}</> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {fareData.extra_stop_status == 1 ? <>
                                <div id="stopFare-div-air">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <hr className="fare-hr" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 px-4 py-4">
                                            <div className="row">
                                                <div className="col-5 p-0" style={{ color: '#212021' }}>Extra Stop Fare</div>
                                                <div className="col-1" style={{ color: '#212021' }}>:</div>
                                                <div className="col-4 p-0" style={{ color: '#212021' }}>
                                                    <span id="extraStopText-air">{parseFloat(fareData.overall_extra_stop_cost).toFixed(2)}</span>&nbsp;&nbsp;SGD
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : <></>}

                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <hr className="fare-hr" />
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-12 px-4 py-4">
                                    <div className='d-flex justify-content-center fare-total'>Total Price : <span id="ma-fare-total">{parseFloat(Math.ceil(fareData.amount)).toFixed(2)} </span>&nbsp;&nbsp;SGD</div>
                                    {/* <div className="row">
                                        <div className="col-5 fare-total p-0">
                                            Total Price
                                        </div>
                                        <div className="col-1 fare-total">
                                            :
                                        </div>
                                        <div className="col-6 fare-list1 p-0">
                                            <span id="ma-fare-total">{parseFloat(Math.ceil(fareData.amount)).toFixed(2)} </span>&nbsp;&nbsp;SGD
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-10 mt-3">
                                    <hr className="fare-hr" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* model for return fare estimate */}
            <div>
                <div className='d-none'>
                    <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#estimateReturn" id="estimateModalReturn" />
                </div>

                <div className="modal fade" id="estimateReturn" data-bs-keyboard="false" tabIndex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="false">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content confirnBook">
                            <div className="d-flex justify-content-end confirnClose">
                                <i className="fa fa-times-circle" data-bs-dismiss="modal" aria-label="Close"></i>
                            </div>
                            <p className="text-center confirm-title">Fare Breakdown</p>
                            <div className="row justify-content-center mt-4">
                                <div className="col-10">
                                    <div className="d-flex">
                                        <div className="pick-circle"></div>
                                        <p className="from-locat">From Location</p>
                                    </div>
                                    <p className="from-addr" id="fare_pick">{rpickup_location}</p>

                                    <hr />

                                    {/* stop */}
                                    {ReStopDivs.length > 0 ?
                                        ReStopDivs.map((data, i) => {
                                            return (
                                                <div id="stop1-div">
                                                    <div className="d-flex">
                                                        <div className="pick-circle"></div>
                                                        <p className="from-locat">Stop {i + 1}</p>
                                                    </div>
                                                    <p className="from-addr" id="stop1-addPi">{data.stop_name}</p>
                                                    <hr />
                                                </div>
                                            )
                                        }) : <></>}

                                    <div className="d-flex">
                                        <div className="drop-circle"></div>
                                        <p className="to-locat">To Location</p>
                                    </div>
                                    <p className="to-addr" id="fare_drop">{rdrop_location}</p>

                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-11 px-4 pt-3 fare-head">
                                    <p id="fare-category">{vehicle_name}</p>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-11 px-4 pt-4 checkout">
                                    <div className="container surge_con" id={fareReData != '' && fareReData.surgeprice.length > 0 ? 'fare-scroll' : ''}>
                                        <table id="fare-table">
                                            {fareReData != '' ? <>
                                                <tr>
                                                    <td>Base Fare</td>
                                                    <td>:</td>
                                                    <td>
                                                        <p className='mb-0 dist-charge'>{parseFloat(fareReData.base_fare).toFixed(2)}<span>SGD</span></p>
                                                    </td>
                                                </tr>
                                                {fareReData.surgeprice.length > 0 ? fareReData.surgeprice.map((data, i) => {
                                                    return (<>
                                                        <tr>
                                                            <td>{data.name}</td>
                                                            <td>:</td>
                                                            <td>
                                                                <p className='mb-0 dist-charge'>{parseFloat(data.surge_amount).toFixed(2)}<span>SGD</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}><hr /></td>
                                                        </tr></>)
                                                }) : <></>}</> : <></>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-md-12 px-4 py-4">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <p className="dist-fare-head">Sub Total</p>
                                            {fareReData != '' ? <>
                                                {fareReData.capped_status == 1 ? <>
                                                    <p className="mb-0 dist-charge" id="fare-sub" style={{ textDecoration: 'line-through' }}> {parseFloat(fareReData.sub_total).toFixed(2)} <span>SGD</span></p>
                                                    <p className="mb-0 dist-charge" id="fare-sub-cap"> {parseFloat(fareReData.cap_price).toFixed(2)} <span>(Capped) SGD</span></p>
                                                    <p className="mb-0 dist-time">Approx</p></> : <>
                                                    <p className="mb-0 dist-charge" id="fare-sub"> {parseFloat(fareReData.sub_total).toFixed(2)} <span>SGD</span></p>
                                                </>}</> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* extra stop cost */}

                            {fareReData != '' && fareReData.extra_stop_status == 1 ? <>
                                <div id="stopFare-div">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <hr className="fare-hr" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-10 px-4 py-4">
                                            <div className="row">
                                                <div className="col-5 p-0" style={{ color: '#212021' }}>Extra Stop Fare</div>
                                                <div className="col-1" style={{ color: '#212021' }}>:</div>
                                                <div className="col-4 p-0" style={{ color: '#212021' }}>
                                                    <span id="extraStopText">{parseFloat(fareReData.overall_extra_stop_cost).toFixed(2)} </span>&nbsp;&nbsp;SGD
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></> : <></>}


                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <hr className="fare-hr" />
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-12 px-4 py-4">
                                    <div className='d-flex justify-content-center fare-total'>Total Price : <span id="fare-total">{parseFloat(FareTotalAmount).toFixed(2)} </span>&nbsp;&nbsp;SGD</div>
                                    {/* <div className="row">
                                        <div className="col-5 fare-total p-0">
                                            Total Price
                                        </div>
                                        <div className="col-1 fare-total">
                                            :
                                        </div>
                                        <div className="col-6 fare-list1 p-0">
                                            <span id="fare-total">{parseFloat(FareTotalAmount).toFixed(2)} </span>&nbsp;&nbsp;SGD
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-10 mt-3">
                                    <hr className="fare-hr" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* add-on model button */}
            <div className='d-none'>
                <input type="button" className="btn rounded-pill" data-bs-toggle="modal" data-bs-target="#AddonData" data-bs-backdrop="false" id="AddonDataModel" />
            </div>
            {!hasRendered ? <></> : <AddonModel AddonData={VehicleAddonData} SelectAddonData={AddonTripType == 'from' ? AddonData : ReAddonData} getAddon={getAddon} />}
        </>
    )
}

export default AirportBook