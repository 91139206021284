import React from 'react'
import BookingManagement from './BookingManagement'

const CurrentJob = () => {
  return (
    <BookingManagement
        type = "Current Booking"
    />
  )
}

export default CurrentJob