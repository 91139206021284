import React from 'react'
import BookingManagement from './BookingManagement'

const CompletedJob = () => {
  return (
    <BookingManagement 
        type = "Completed Jobs"
    />
  )
}

export default CompletedJob